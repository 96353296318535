var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c("div", { staticClass: "flex flex-wrap" }, [
        _c("div", { staticClass: "limiter scrollbar" }, [
          _c(
            "div",
            { staticClass: "flex flex-column justify-center items-center mt2" },
            [
              _c("img", {
                attrs: { src: require("@/assets/error.svg"), alt: "" },
              }),
              _c("p", { staticClass: "f3 tc light-gray fw2" }, [
                _c("span", [_vm._v("Page not found")]),
              ]),
              _c("p", { staticClass: "f5 tc light-gray fw2" }, [
                _c("span", [
                  _c(
                    "a",
                    { staticClass: "white pointer", attrs: { href: "/" } },
                    [_vm._v("Go back")]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }